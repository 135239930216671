import React, { useEffect, useState } from 'react'
import { checkQuyen } from '../utils/config'
import { Collapse, Drawer, InputNumber, Popconfirm, Result, Spin, Switch, Table, Tag, Tooltip, Upload, message, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getKhenThuongChiTietDis, getKhenThuongDis } from '../redux/khenThuongReducer';
import { BE_DOMAIN, BE_DOMAIN_FILE } from '../api/api';
import { createKhenThuongApi, removeFileApi, suaKhenThuongChiTietApi, xoaKhenThuongChiTietApi } from '../api/khenThuongApi';
import FormKhenThuong from './FormKhenThuong';
import { LoadingOutlined } from '@ant-design/icons';
import { getNguoiDungDis } from '../redux/nguoiDungReducer';


const NoiDungKhenThuong = () => {

    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data, danhSachKhenThuongChiTiet } = useSelector((state) => state.khenThuongReducer);
    const { data: dataUser } = useSelector((state) => state.nguoiDungReducer);


    const [load, setLoad] = useState(false)

    const [open, setOpen] = useState(false);
    const [khenThuongChiTiet, setKhenThuongChiTiet] = useState(null)

    const onClose = () => {
        setOpen(false);
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {
        api.success({
            message: 'Đã lưu !',
            showProgress: true,
            duration: 1
        });
    }


    const { id } = useParams()
    const [fileList, setFileList] = useState([]);


    useEffect(() => {
        dispatch(getKhenThuongDis(id))
        dispatch(getNguoiDungDis())

    }, [id])



    const props = {
        name: 'file',
        action: `${BE_DOMAIN}/file/upload-file`,
        fileList: fileList,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            setFileList(info.fileList)
            if (info.file.status !== 'uploading') {

            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} upload thành công !`);
                setFileList([])
                // console.log(info.file)
                let model = {
                    ma_danh_muc: id,
                    file_dinh_kem: JSON.stringify([{
                        fileName: info.file.response.filename,
                        title: info.file.name
                    }]),
                    phan_hoi: ""
                }
                if (data && data.file_dinh_kem) {
                    let lstFile = JSON.parse(data.file_dinh_kem)
                    model.file_dinh_kem = JSON.stringify([...lstFile, {
                        fileName: info.file.response.filename,
                        title: info.file.name
                    }])
                    model.phan_hoi = data.phan_hoi
                }


                createKhenThuongApi(model).then(res => {
                    dispatch(getKhenThuongDis(id))
                })

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    let lstFile = []
    if (data && data.file_dinh_kem) {
        lstFile = JSON.parse(data.file_dinh_kem)
    }

    let columns = [
        {
            title: 'Loại',
            key: 'loai',
            render: (_, record) => {
                return <Tag>
                    {record.loai == 0 ? "Cá nhân" : "Đơn vị"}
                </Tag>
            },
        },
        {
            title: 'Tên',
            key: 'ten_dai_dien',
            render: (_, record) => {
                return <label>
                    {record.ten_day_du}
                </label>
            },
        },

        {
            title: 'Chức vụ/Loại tập thể',
            key: 'chuc_vu',
            render: (_, record) => {
                return <label>
                    {record.chuc_vu}
                </label>
            },
        },
        {
            title: 'Link minh chứng',
            key: 'link_minh_chung',
            render: (_, record) => {
                return <label>
                    {record.link_minh_chung}
                </label>
            },
        },
        {
            title: 'Thẩm định',
            key: 'tham_dinh',
            render: (_, record) => {
                return <label>
                    <b>Nhận xét của VP: </b>
                    {record.nhan_xet_cua_vp}
                    <br />
                    <b>Nhận xét của ban phụ trách: </b>
                    {record.nhan_xet_phu_trach}
                </label>
            },
        },
        {
            title: 'Duyệt',
            key: 'duyet',
            render: (_, record) => {
                return <label>
                    {checkQuyen() ?
                        <Switch checked={record.da_duyet} onChange={(value) => {
                            let model = { ...record, da_duyet: value }

                            suaKhenThuongChiTietApi(record.id, model).then(res => {
                                dispatch(getKhenThuongChiTietDis(data.id))
                            })
                        }} />
                        :
                        record.da_duyet ?
                            <Tag color='blue'>Đã duyệt</Tag>
                            :
                            <Tag color='red'>Chưa duyệt</Tag>
                    }

                </label>
            },
        },
        {
            title: ' ',
            key: 'action',
            render: (_, record) => {
                return <label>

                    {
                        record.da_duyet ?
                            <Tooltip title="Không thể chỉnh sửa khi đã duyệt !">
                                <button className='opacity-50 btn btn-sm btn-primary m-2' >
                                    <i className='fas fa-edit'></i>
                                </button>
                            </Tooltip>

                            :
                            <button className='btn btn-sm btn-primary m-2' onClick={() => {
                                setKhenThuongChiTiet(record)
                                setOpen(true)

                            }}>
                                <i className='fas fa-edit'></i>
                            </button>
                    }

                    {
                        record.da_duyet ?
                            <Tooltip title="Không thể xóa khi đã duyệt !">
                                <button className='opacity-50 btn btn-sm btn-danger m-2'>
                                    <i className='fas fa-trash'></i>
                                </button>
                            </Tooltip>

                            :
                            <Popconfirm
                                title="Bạn chắc muốn tiếp tục ?"
                                onConfirm={() => {
                                    xoaKhenThuongChiTietApi(record.id).then(res => {

                                        dispatch(getKhenThuongChiTietDis(data.id))

                                    })
                                }}
                                okText="Có"
                                cancelText="Không"
                            >
                                <button className='btn btn-sm btn-danger m-2'>
                                    <i className='fas fa-trash'></i>
                                </button>

                            </Popconfirm>
                    }

                </label>
            },
        },

    ]

    const noiDungChiTiet = (nguoiDungId) => {

        return <Table columns={columns} dataSource={danhSachKhenThuongChiTiet.filter(n => n.nguoi_dung_id == nguoiDungId).sort((a, b) => b.id - a.id)} />
    }

    let itemCollapse = []
    danhSachKhenThuongChiTiet.map(item => {

        if (!itemCollapse.find(n => n.key == item.nguoi_dung_id)) {
            let nguoiDung = dataUser.find(n => n.id == item.nguoi_dung_id)
            if (nguoiDung)
                itemCollapse.push({
                    key: nguoiDung.id,
                    label: nguoiDung.ten_dai_dien,
                    children: <p>
                        {noiDungChiTiet(nguoiDung.id)}
                    </p>
                })
        }
    })

    return (
        <div>
            {contextHolder}

            <div className="card" style={{ width: '100%' }}>
                <div className="card-body row">
                    <div className='col-6'>
                        <b className="card-title">Đơn vị đính kèm các tập tin theo quy định (công văn có xác nhận cấp ủy, biên bản họp của BCH ...):</b>
                        <br />
                        <Upload {...props}>
                            <button className='btn btn-outline-secondary my-2' > <i className='fas fa-upload'></i> Tải file đính kèm</button>
                        </Upload>
                        {
                            lstFile.map(item => {
                                return <Tag className='m-1' color='blue'>
                                    <a className='text-decoration-none text-primary' href={`${BE_DOMAIN_FILE + item.fileName}`} target='_blank'>
                                        {item.title}
                                    </a>
                                    <Popconfirm
                                        title="Bạn chắc muốn tiếp tục ?"
                                        onConfirm={async () => {
                                            await removeFileApi(item.fileName)

                                            if (data && data.file_dinh_kem) {
                                                let lstFile = JSON.parse(data.file_dinh_kem)
                                                let model = { ...data }
                                                model.file_dinh_kem = JSON.stringify(lstFile.filter(n => n.fileName != item.fileName))

                                                createKhenThuongApi(model).then(res => {
                                                    dispatch(getKhenThuongDis(id))
                                                })
                                            }


                                        }}
                                        okText="Có"
                                        cancelText="Không"
                                    >

                                        <i className='fas fa-trash mx-2 text-danger'></i>
                                    </Popconfirm>
                                </Tag>
                            })
                        }

                    </div>
                    <div className='col-1'></div>
                    <div className='col-5'>

                        <b className="card-title">Phản hồi của cấp thành:
                            <Tooltip title="Hãy thêm dữ liệu để phản hồi !">
                                <i className="fas fa-question-circle"></i>
                            </Tooltip></b>

                        {load ?
                            <label className='text-secondary'>
                                <Spin className='mx-2' size="small" />
                                saving...
                            </label>
                            :
                            <label className='text-success'>
                                <i className='fas fa-check mx-2'></i>
                                save
                            </label>
                        }

                        {data ?
                            checkQuyen() ? <textarea className='form-control' rows={5} onChange={(event) => {
                                let value = event.target.value;
                                let model = { ...data, phan_hoi: value }
                                setLoad(true)
                                setTimeout(() => {
                                    createKhenThuongApi(model).then(res => {
                                        setLoad(false)
                                    })
                                }, 1000);

                            }} />
                                :
                                <textarea className='form-control' rows={5} value={data.phan_hoi} />
                            : ""
                        }

                    </div>


                </div>
            </div>
            <button className='btn btn-success btn-sm my-2' onClick={() => {
                setKhenThuongChiTiet({ id: 0, khen_thuong_id: data.id })
                setOpen(true)
            }} >
                <i className='fas fa-plus'></i>  Thêm khen thưởng

            </button>


            {checkQuyen() ?
                <Collapse items={itemCollapse} />
                :
                noiDungChiTiet(userLogin.id)
            }


            <Drawer
                title={`Chấm điểm`}
                placement="right"
                width={"30%"}
                onClose={onClose}
                open={open}
            >
                <FormKhenThuong khenThuong={khenThuongChiTiet} onClose={onClose} />
            </Drawer>
        </div >
    )
}

export default NoiDungKhenThuong